import React from 'react'
import Image from 'gatsby-image'

function NonStretchedImage(props) {
  const { fluid, style } = props
  let normalizedProps = props
  if (fluid && fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(style || {}),
        maxWidth: fluid.presentationWidth,
        margin: '0 auto', // Used to center the image
      },
    }
  }

  return <Image {...normalizedProps} />
}

export default NonStretchedImage
