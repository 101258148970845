import React from 'react'
import { phoneNumber, faxNumber } from '../utils/config'

export function RetailHours() {
  return (
    <div className="flex--item-full">
      <h3>Retail Store Hours:</h3>
      <p>
        Everyday from 9:00am - 5:00pm
      </p>
    </div>
  )
}
export function ServiceHours() {
  return (
    <div className="flex--item-full">
      <h3>Service &amp; Repair Shop Hours:</h3>
      <p>
        Everyday from 9:00am - 5:00pm
      </p>
    </div>
  )
}

export function ContactInfo() {
  return (
    <div className="flex--item-full">
      <h3>Address:</h3>
      <p>
        215 U.S. Route 1
        <br />
        Falmouth, ME 04105
        <br />
        Phone:{' '}
        <a className="link" href={`tel:${phoneNumber}`}>
          (207) 210-6680
        </a>
        <br />
        Fax:{' '}
        <a className="link" href={`tel:${faxNumber}`}>
          (207) 210-6681
        </a>
      </p>
    </div>
  )
}
