import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'
import NonStretchedImage from '../components/NonStretchedImage'
import { RetailHours, ServiceHours, ContactInfo } from '../components/StoreInfo'

export default class IndexPage extends Component {
  render() {
    const { data } = this.props
    const currentPage = data.wordpressPage
    const { title, content, acf } = currentPage
    return (
      <Layout pageTitle={title}>
        <div className="hero">
          <Image
            className="hero-inner"
            fluid={acf.hero_image.localFile.childImageSharp.fluid}
          />
          <div className="hero-logo hero-logo-position " />
        </div>
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <div className="container--content">
            <NonStretchedImage
              fluid={data.localBanner.localFile.childImageSharp.fluid}
              presentationWidth
            />
          </div>
          <div className="container--content">
            <NonStretchedImage
              fluid={data.storeExterior.localFile.childImageSharp.fluid}
              presentationWidth
            />
            <h2 className="headline underline">Falmouth Ace Hardware</h2>
            <div className="flex--col-row">
              <ContactInfo />
              <RetailHours />
              <ServiceHours />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      slug
      title
      content
      acf {
        hero_image {
          id
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    localBanner: wordpressWpMedia(localFile: { name: { eq: "local-banner" } }) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 704) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
    storeExterior: wordpressWpMedia(
      localFile: { name: { eq: "store-exterior" } }
    ) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
    currentPromo: wordpressWpMedia(
      localFile: { name: { eq: "may-2019-grill-ad-1a" } }
    ) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
    hero: wordpressWpMedia(localFile: { name: { eq: "home" } }) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
    heroPromo: wordpressWpMedia(localFile: { name: { eq: "bbq-fest-promo" } }) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  }
`
